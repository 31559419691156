import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import ReactCodeInput from 'react-code-input';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SLASH } from '../../constants/routes';
import { isLocalhost } from '../../serviceWorker';
import { getLocation } from '../../actions/Locations';
import { getOrg } from '../../actions';
import { locationTerminology } from '../../services/locations';
import { getDocument, resetDocument } from '../../actions/Documents';
import AuthContainer from '../auth/Container';
import EmployerAgreement from '../EmployerAgreement';
import Loading from '../Loading';
import ApprenticeAgreement from '../ApprenticeAgreement';

const Document = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  // redux
  const organization = useSelector((state) => state.organization);
  const orgLocation = useSelector((state) => state.orgLocation);
  const doc = useSelector((state) => state.doc);
  // Search Params
  const searchParams = useMemo(() => {
    return new URLSearchParams(window.location.search);
  }, []);
  // Organization
  const orgType = organization?.type || 'community'; // TODO should this default to null?
  // Employer
  const locationTerm = locationTerminology({ orgType });
  const employerName = orgLocation?.name || null;
  const employerImage = orgLocation?.locationImage || null;
  // Access Code
  const [accessCodeApproved, setAccessCodeApproved] = useState(false);
  const accessCode = doc?.accessCode || '';
  const showAccessCodePrompt = useMemo(() => {
    if (accessCodeApproved) {
      return false;
    }

    return true;
  }, [accessCodeApproved]);

  const handleCheckAccessCode = useCallback((accessCodeToCheck) => {
    const isEqual = accessCode && accessCodeToCheck && parseInt(accessCodeToCheck, 10) === accessCode;

    toast.dismiss('accessCodeInvalid');

    setAccessCodeApproved(isEqual);

    if (!isEqual && accessCodeToCheck.length === 6) {
      toast.error('Invalid access code.', { toastId: 'accessCodeInvalid', autoClose: false });
    }
  }, [accessCode]);

  useEffect(() => {
    dispatch(getDocument(params?.docId, params?.collection)).then((response) => {
      // If no Document found (401) or there's no orgId
      if (response?.status === 401 || !response?.orgId) {
        history.replace(SLASH);
        console.error(response?.message);

        return false;
      }

      // TODO:
      // Use Page Organization
      // or create Doc Organization
      dispatch(getOrg({
        orgId: response?.orgId,
        select: [
          'fields.name',
          'fields.orgLogo'
        ],
        locationIds: [response?.locationId],
        locationSelect: [
          'fields.name',
          'fields.addressLine1',
          'fields.addressLine2',
          'fields.addressCity',
          'fields.addressState',
          'fields.addressPostalCode',
          'fields.defaultLocation'
        ]
      }));

      if (response?.locationId) {
        dispatch(getLocation(response?.locationId));
      }
    });

    return function cleanup() {
      dispatch(resetDocument());
    };
  }, [dispatch, history, params?.collection, params?.docId]);

  useEffect(() => {
    if (accessCode && searchParams.has('accessCode')) {
      handleCheckAccessCode(searchParams.get('accessCode'));
    }
  }, [accessCode, handleCheckAccessCode, searchParams]);

  // useEffect(() => {
  //   if (!showAccessCodePrompt) {
  //     if (invite?.apprenticeshipId) {
  //       dispatch(getApprenticeship(invite?.apprenticeshipId));
  //     }
  //   }
  // }, [showAccessCodePrompt]);

  if (!params?.collection || !params?.docId) {
    return (
      <Redirect to={SLASH} />
    );
  }

  if (params?.docId && !doc?.id) {
    return (
      <Loading
        text="Loading Document..."
        className='theme-dark'
      />
    );
  }

  // Organization
  if (!organization?.id) {
    return (
      <Loading
        text="Loading Organization..."
        className='theme-dark'
      />
    );
  }

  // Location / Employer
  if (!orgLocation?.id) {
    return (
      <Loading
        text={`Loading ${locationTerm.text}...`}
        className='theme-dark'
      />
    );
  }

  if (showAccessCodePrompt) {
    return (
      <div
        className='d-flex align-items-center justify-content-center'
        style={{
          height: '100vh'
        }}
      >
        <AuthContainer
          title="Access Code"
          logo={!!employerImage}
          locationImage={employerImage}
          locationName={employerName}
          footerText="Enter the access code associated with this invitation."
        >
          <div
            className='mt-4 d-flex justify-content-center'
          >
            <ReactCodeInput
              type='text'
              fields={6}
              autoFocus
              value={isLocalhost ? accessCode.toString() : ''}
              onChange={handleCheckAccessCode}
            />
          </div>
        </AuthContainer>

        {accessCode && (
          <div
            id="turbineInvitation"
            onClick={() => {
              handleCheckAccessCode(accessCode ? accessCode.toString() : '');
            }}
            aria-hidden
          />
        )}
      </div>
    );
  }

  // Employer Agreement : Management Signature
  if (params?.collection === 'employerAgreements') {
    return (
      <EmployerAgreement
        hideHeader
        showPrintButton
        employerProfileId={doc?.employerProfileId}
        employerAgreementId={doc?.id}
        apprenticeshipId={doc?.apprenticeshipId}
        data={doc}
      />
    );
  }

  if (params?.collection === 'apprenticeAgreements') {
    return (
      <ApprenticeAgreement
        userProfileId={doc?.employerProfileId}
        apprenticeAgreementId={doc?.id}
        apprenticeshipId={doc?.apprenticeshipId}
        data={doc}
        dataType="doc"
      />
    );
  }

  return (
    <div>
      Document: {doc?.id}
    </div>
  );
};

export default Document;
