import React from 'react';
import { OrgLogo } from '@apprentage/components';
import { IMAGE_TURBINE_BLACK } from '../../constants/assets';
import { PLATFORM_NAME } from '../../constants/globals';
import { TURBINE_MARKETING_SITE } from '../../constants/urls';

const Footer = ({
  text,
  className = '',
  logoHeight = '15',
  trackingCode,
  poweredBy = false,
  orgLogo = '',
  orgName = ''
}) => {
  return (
    <div
      className={`tw-assistant-footer ${className}`}
    >
      <span>{text}</span>
      {poweredBy ? (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${TURBINE_MARKETING_SITE}?track=${trackingCode}`}
          title={`Powered by ${PLATFORM_NAME}`}
        >
          <img
            src={IMAGE_TURBINE_BLACK}
            alt={PLATFORM_NAME}
            height={logoHeight}
            style={{
              height: `${logoHeight}px`
            }}
          />
        </a>
      ) : (
        <div>
          <OrgLogo
            className="mx-auto"
            src={orgLogo}
            alt={orgName}
            height={logoHeight}
          />
        </div>
      )}
    </div>
  );
};

export default Footer;
