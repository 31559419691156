import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import OrgLogo from '@apprentage/components/dist/components/OrgLogo';
import { fetchDashboard } from '../../services/dashboards';
import {
  DASHBOARD_DEFAULT_LAYOUT,
  LAYOUTS
} from '../../constants/dashboards';
import { redirectToAuth } from '../../services/auth';
import { TURBINE_ADMIN, TURBINE_PAGE } from '../../constants/urls';
import { getPage, resetPage } from '../../actions/Pages';
import { getOrg } from '../../actions';
import { SET_PAGE_ORG } from '../../actions/types';
import { analyticsTracking } from '../../services/analytics';
import {
  ANDROID_192_ICON, ANDROID_512_ICON, APPLE_TOUCH_ICON, ROBOTS_OFF, ROBOTS_ON
} from '../../constants/globals';
import generateWebManifest from '../../utils/generateWebManifest';
import { isLocalhost } from '../../serviceWorker';
import pageInIframe from '../../utils/pageInIframe';
import withRouteClassName from '../../components/App/withRouteClassName';
import Module from './DraggableWidgetsDashboard/Module';
import Loading from '../../components/Loading';
import './style.css';

const Page = () => {
  const params = useParams();
  const dispatch = useDispatch();
  // Redux
  const pageOrganization = useSelector((state) => state.pageOrganization);
  const currentUser = useSelector((state) => state.currentUser);
  const page = useSelector((state) => state.page);
  const pageFetched = useSelector((state) => state.pageFetched);
  // Params
  const pageId = params?.pageId || null;
  const orgSlug = params?.orgSlug || null;
  // Organization
  const orgId = pageOrganization?.id || null;
  const orgName = pageOrganization?.name || null;
  const appleTouchIcon = pageOrganization?.appleTouchIcon || APPLE_TOUCH_ICON;
  const androidIconSmall = pageOrganization?.androidIconSmall || ANDROID_192_ICON;
  const androidIconLarge = pageOrganization?.androidIconLarge || ANDROID_512_ICON;
  const searchEnginesEnabled = false;
  // Page
  const pageTitle = page?.title || null;

  // Local State Dashboard
  const [dashboardData, setDashboardData] = useState({});
  const [zones, setZones] = useState(null);
  // {
  //   zone1: [],
  //   zone2: [],
  //   zone3: []
  // }
  const [isDashboardDataLoading, setIsDashboardDataLoading] = useState(true);
  const [isZoneDataAvailable, setIsZoneDataAvailable] = useState(false);

  const metaDataTitle = useMemo(() => {
    return `${pageTitle || 'Page'} | ${orgName || 'Turbine Workforce'}`;
  }, [orgName, pageTitle]);

  useEffect(() => {
    if (pageId) {
      dispatch(getPage(pageId)).catch((error) => {
        console.error(error);
        toast.error(error.message);
      });
    }
  }, [dispatch, pageOrganization?.id, pageId, orgId]);

  useEffect(() => {
    if (!pageInIframe && !isLocalhost && pageId && page?.id) {
      if (sessionStorage.getItem(`tw-page-${pageId}`)) {
        // Page View
        analyticsTracking({
          refTable: 'pages',
          refId: page?.id,
          userId: currentUser?.id || null,
          orgId: page?.orgId
        });
      } else {
        // New Visit & Page View
        analyticsTracking({
          refTable: 'pages',
          refId: page?.id,
          userId: currentUser?.id || null,
          orgId: page?.orgId,
          isUnique: true
        });
      }

      sessionStorage.setItem(`tw-page-${pageId}`, new Date().toISOString());
    }
  }, [pageId, page?.id, page?.orgId, currentUser?.id]);

  useEffect(() => {
    if (page?.id && orgSlug) {
      dispatch(getOrg({
        reduxKey: 'pageOrganization',
        actionType: SET_PAGE_ORG,
        slug: orgSlug,
        select: [
          'fields.name',
          'fields.orgLogo',
          'fields.slug',
          'fields.appleTouchIcon',
          'fields.androidIconSmall',
          'fields.androidIconLarge'
        ],
        order: 'fields.name'
        // locationIds: [locationId],
        // locationSelect: ['fields.name']
      }));
    }
  }, [dispatch, orgSlug, page?.id]);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetPage());
    };
  }, [dispatch]);

  const getDashboardData = useCallback(() => {
    if (page?.dashboardId) {
      setIsDashboardDataLoading(true);
      fetchDashboard(page?.dashboardId).then((response) => {
        if (response !== null) {
          setZones((prevZones) => ({
            ...prevZones,
            zone1: response?.zone1 || [],
            zone2: response?.zone2 || [],
            zone3: response?.zone3 || []
          }));
          setDashboardData({
            ...response
          });
          setIsZoneDataAvailable(Boolean(response?.id)); // TODO change var name
          setIsDashboardDataLoading(false);
        } else {
          setIsDashboardDataLoading(false);
        }
      });
    }
  }, [page?.dashboardId]);

  useEffect(() => {
    getDashboardData();
  }, [getDashboardData]);

  // Dynamic Web Manifest
  useEffect(() => {
    const manifest = document.getElementById('dynamic-manifest');

    // The id attribute is removed in generateWebManifest
    // to ensure this runs one time
    if (orgId && manifest) {
      generateWebManifest({
        orgName,
        androidIconSmall,
        androidIconLarge
      });
    }
  }, [androidIconLarge, androidIconSmall, orgId, orgName]);
  // /Dynamic Web Manifest

  if (pageId && !page?.id) {
    return <Loading />;
  }

  if (!page?.id && pageFetched) {
    return (
      <div>
        REDIRECT TO PAGE LINK ERROR (no page)
      </div>
    );
  }

  // if (page?.id && page?.pageLinkId === pageLink?.id) {
  //   return (
  //     <div>
  //       REDIRECT TO PAGE LINK ERROR (no page)
  //     </div>
  //   );
  // }

  if (page?.id && isDashboardDataLoading) {
    return <Loading />;
  }

  if (!isDashboardDataLoading && !isZoneDataAvailable) { // && !canManageOrgDashboard(role)
    // Dashboard data was fetched, No Dashboard Data available, Can NOT create a Dashboard
    // Redirect user away from Org Dashboard page
    return (
      <div>
        REDIRECT TO PAGE LINK ERROR (no dashboard)
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{metaDataTitle}</title>
        {appleTouchIcon && (
          <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        )}
        <meta name="robots" content={searchEnginesEnabled ? ROBOTS_ON : ROBOTS_OFF} />

        {page?.integration && (
          <script async src={`${TURBINE_PAGE}/turbine-assistants.js`} data-id={page?.integration} data-version="1" />
        )}
      </Helmet>

      {!isDashboardDataLoading && isZoneDataAvailable && (
        <>
          {(page?.showOrgLogo || page?.showTitle) && (
            <>
              {page?.showOrgLogo && (
                <div className='mt-4'>
                  <OrgLogo
                    className="mx-auto"
                    src={pageOrganization?.orgLogo}
                    alt={pageOrganization?.name}
                    height="30"
                  />
                </div>
              )}

              {page?.showTitle && (
                <div className={`h4 ${page?.showOrgLogo ? 'mt-2' : 'mt-4'} mx-3 text-center`}>
                  {page?.title}
                </div>
              )}
            </>
          )}

          <div className={`row ${page?.showOrgLogo || page?.showTitle ? 'mt-3' : 'mt-4'} bg-light`}>
            {Object.keys(zones).map((zoneId, zoneIndex) => {
              return (
                <div
                  key={zoneIndex}
                  className={`col-12 col-sm-${LAYOUTS[dashboardData.activeLayout || DASHBOARD_DEFAULT_LAYOUT][zoneIndex]} w-100`}
                >
                  {zones[zoneId]?.map((module, index) => (
                    <div key={index}>
                      <Module
                        orgId={orgId}
                        type={module.moduleType}
                        className="mb-3"
                        moduleData={module}
                      />
                    </div>
                  ))}
                </div>
              );
            })}
          </div>

          <div className="d-flex justify-content-center my-3">
            <button
              type="button"
              className="btn btn-outline-primary btn-sm"
              onClick={() => {
                redirectToAuth({
                  organizationSlug: pageOrganization?.slug,
                  continueUrl: `${TURBINE_ADMIN}/org/page/${page?.id}`
                });
              }}
            >
              Login
            </button>
          </div>

        </>
      )}
    </>
  );
};

export default withRouteClassName(Page);
