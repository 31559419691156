import React from 'react';
import { PLATFORM_NAME } from '../../constants/globals';
import { TURBINE_MARKETING_SITE } from '../../constants/urls';
import { IMAGE_TURBINE_BLACK } from '../../constants/assets';
import './style.css';

const PoweredBy = ({
  theme = 'light',
  className = '',
  logo = false,
  logoHeight = 15,
  trackingCode = 'poweredBy'
}) => {
  return (
    <div
      className={`poweredby ${className} ${theme === 'dark' ? 'theme-dark' : ''}`}
    >
      <span>Powered by</span>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`${TURBINE_MARKETING_SITE}?track=${trackingCode}`}
        title={`Powered by ${PLATFORM_NAME}`}
      >
        {logo ? (
          <img
            src={IMAGE_TURBINE_BLACK}
            alt={PLATFORM_NAME}
            height={logoHeight}
            style={{
              height: `${logoHeight}px`,
              marginLeft: '4px'
            }}
          />
        ) : PLATFORM_NAME }
      </a>
    </div>
  );
};

export default PoweredBy;
