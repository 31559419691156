import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import ReactCodeInput from 'react-code-input';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { INVITE_MESSAGE_BASE, SLASH } from '../../constants/routes';
import { isLocalhost } from '../../serviceWorker';
import { getOrg } from '../../actions';
import { getPageLink, resetPageLink } from '../../actions/PageLinks';
import { analyticsTracking } from '../../services/analytics';
import { IMAGE_TURBINE_RETRO_ICON_WHITE } from '../../constants/assets';
import pageInIframe from '../../utils/pageInIframe';
import AuthContainer from '../../components/auth/Container';
import Loading from '../../components/Loading';

const PageLink = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  const pageLink = useSelector((state) => state.pageLink);
  // Params
  const pageLinkId = params?.pageLinkId || null;
  // Search Params
  const searchParams = new URLSearchParams(document.location.search);
  const paramsHasAccessCode = searchParams.has('accessCode');
  const paramsAccessCode = searchParams.get('accessCode');
  // Access Code
  const [accessCodeApproved, setAccessCodeApproved] = useState(false);
  const accessCode = pageLink?.accessCode || null;
  const showAccessCodePrompt = useMemo(() => {
    if (pageLink?.id) {
      if (accessCodeApproved) {
        return false;
      }

      if (pageLink?.accessCode) {
        return true;
      }
    }

    return false;
  }, [pageLink?.id, pageLink?.accessCode, accessCodeApproved]);

  const handleCheckAccessCode = useCallback((accessCodeToCheck) => {
    const isEqual = accessCode && accessCodeToCheck && parseInt(accessCodeToCheck, 10) === accessCode;
    toast.dismiss('accessCodeInvalid');
    setAccessCodeApproved(isEqual);

    if (!isEqual && accessCodeToCheck.length === 6) {
      toast.error('Invalid access code.', { toastId: 'accessCodeInvalid', autoClose: false });
    }
  }, [accessCode]);

  useEffect(() => {
    dispatch(getPageLink(pageLinkId)).then((response) => {
      // If no Document found (401) or there's no orgId
      if (response?.status === 401 || !response?.orgId) {
        history.replace(`${INVITE_MESSAGE_BASE}/error`); // TODO update to page error page
        console.error(response?.message);

        return false;
      }

      dispatch(
        getOrg({
          orgId: response?.orgId,
          select: ['fields.name', 'fields.orgLogo', 'fields.slug'],
          locationIds: [response?.locationId],
          locationSelect: ['fields.name']
        })
      );
    });

    return function cleanup() {
      dispatch(resetPageLink());
    };
  }, [dispatch, history, pageLinkId]);

  useEffect(() => {
    if (!pageInIframe && !isLocalhost && pageLink?.id) {
      if (sessionStorage.getItem(`pageLink-${pageLinkId}`)) {
        // Page View
        analyticsTracking({
          refTable: 'pageLinks',
          refId: pageLink?.id,
          userId: currentUser?.id || null,
          orgId: pageLink?.orgId
        });
      } else {
        // New Visit & Page View
        analyticsTracking({
          refTable: 'pageLinks',
          refId: pageLink?.id,
          userId: currentUser?.id || null,
          orgId: pageLink?.orgId,
          isUnique: true
        });
      }

      sessionStorage.setItem(`pageLink-${pageLinkId}`, new Date().toISOString());
    }
  }, [currentUser?.id, pageLink?.id, pageLink?.orgId, pageLinkId]);

  useEffect(() => {
    if (accessCode && paramsHasAccessCode) {
      handleCheckAccessCode(paramsAccessCode);
    }
  }, [accessCode, handleCheckAccessCode, paramsAccessCode, paramsHasAccessCode]);

  if (!pageLinkId) {
    return <Redirect to={SLASH} />;
  }

  if (pageLinkId && !pageLink?.id) {
    return (
      <Loading
        text="Loading..."
        className="theme-dark"
      />
    );
  }

  // Organization
  if (!organization?.id) {
    return (
      <Loading
        text="Loading..."
        className="theme-dark"
      />
    );
  }

  if (showAccessCodePrompt) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          height: '100vh'
        }}
      >
        <AuthContainer
          title="Access Code"
          footerText="Enter access code to continue."
        >
          <div className="mt-4 d-flex justify-content-center">
            <ReactCodeInput
              type="text"
              fields={6}
              autoFocus
              value={isLocalhost ? accessCode.toString() : ''}
              onChange={handleCheckAccessCode}
            />
          </div>
        </AuthContainer>
      </div>
    );
  }

  if (pageLink?.url) {
    setTimeout(() => {
      document.location = pageLink?.url;
    }, 1000);

    return (
      <div
        className='vh-100 text-white d-flex flex-column align-items-center justify-content-center'
      >
        <span
          className="overflow-hidden"
          style={{
            borderRadius: '1.5rem',
            boxShadow: '0px 0px 4rem rgba(255, 255, 255, 0.5)'
          }}
        >
          <img
            src={IMAGE_TURBINE_RETRO_ICON_WHITE}
            height={60}
            style={{
              height: '60px'
            }}
            alt="Turbine"
          />
        </span>
        <div className='mt-3'>
          Redirecting to...
        </div>
        <div className='mt-1'>
          {pageLink?.url}
        </div>
      </div>
    );
  }

  if (pageLink?.pageId) {
    // TODO revisit link route structure
    return <Redirect to={`/o/${organization?.slug}/page/${pageLink?.pageId}`} />;
  }

  // TODO show UI "pageLink has an unsupported type"

  return <div>ID: {pageLink?.id}</div>;
};

export default PageLink;
