import React from 'react';
import LinkWidgetModule from './Modules/LinkWidgetModule';
import EmbedWidgetModule from './Modules/EmbedWidgetModule';

const Module = ({
  type,
  className,
  isConfig = false,
  moduleData,
  orgId = null
}) => {
  let module = null;

  switch (type) {
    case 'LINK_WIDGETS_LIST':
      module = <LinkWidgetModule orgId={orgId} widgetIds={moduleData.selectedIds} />;
      break;
    case 'EMBED_WIDGETS_LIST':
      module = <EmbedWidgetModule orgId={orgId} widgetIds={moduleData.selectedIds} />;
      break;
    default:
      module = null;
  }
  return (
    <div
      className={className}
      style={isConfig ? { pointerEvents: 'none' } : {}}
    >
      {module}
    </div>
  );
};

export default Module;
