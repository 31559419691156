import React, { useEffect, useState } from 'react';
import { fetchWidgets } from '../../../../services/widgets';
import { IMAGE_TOOLKIT_EMPTY } from '../../../../constants/assets';
import EmbedWidget from '../../../../components/ManageContent/EmbedWidget';

const EmbedWidgetModule = ({
  className,
  widgetIds,
  orgId = null
}) => {
  // Local State
  const [embedWidgets, setEmbedWidgets] = useState([]);
  const [showNoDataUi, setShowNoDataUi] = useState(false);

  const getEmbedClassName = (type, embedCode) => {
    if (type === 'embed') {
      if (embedCode.includes('youtube') || embedCode.includes('vimeo')) {
        return 'embed-container position-relative';
      }
    }
    return '';
  };

  useEffect(() => {
    if (Array.isArray(widgetIds) && widgetIds?.length > 0) {
      fetchWidgets({
        orgId,
        type: 'embed',
        ids: widgetIds,
        sortByIds: widgetIds
      }).then((response) => {
        const list = response?.list || [];
        if (list.length > 0) {
          setShowNoDataUi(false);
        } else {
          setShowNoDataUi(true);
        }
        setEmbedWidgets(list);
      });
    } else {
      setEmbedWidgets([]);
      setShowNoDataUi(true);
    }
  }, [orgId, widgetIds]);

  if (showNoDataUi) {
    return (
      <div
        className="d-flex flex-column justify-content-center align-items-center justify-content-center"
        style={{
          minHeight: '400px'
        }}
      >
        <img
          src={IMAGE_TOOLKIT_EMPTY}
          className="mb-3"
          height={150}
          style={{
            height: '150px'
          }}
          alt="No Embeds"
        />
        <p className="mt-1 mb-3">No embeds.</p>
      </div>
    );
  }
  return (
    <div className={`container ${className}`}>
      <div className="d-flex flex-column">
        {embedWidgets?.map((widget) => {
          const embedClassName = getEmbedClassName(
            widget.type,
            widget?.embedCode
          );
          return (
            <div key={widget.id}>
              <EmbedWidget
                embedCode={widget.embedCode || ''}
                title={widget.title || ''}
                className={embedClassName}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EmbedWidgetModule;
