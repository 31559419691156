import { toast } from 'react-toastify';
import { isLocalhost } from '../serviceWorker';
import { client, flattenItems } from '../services/contentful';
import formatData from '../services/formatData';
import { updateEntry } from '../services/entry';
import {
  SET_ORGS,
  SET_ORG,
  RESET_ORG,
  SET_ORG_EDIT,
  RESET_ORG_EDIT,
  RESET_PAGE_ORG
} from './types';
import { getLocations } from './Locations';
import { fetchOrg } from '../services/organizations';

const emailUser = (emailData) => {
  // TODO use turbine email api
  console.log(emailData);
};

export const getOrg = ({
  orgId,
  slug,
  include = 3,
  select,
  order,
  locationIds,
  locationSelect,
  reduxKey = 'organization',
  actionType = SET_ORG
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!orgId && !slug) {
        const orgFailedError = 'An id or slug are required to get an Organization';

        toast.error(orgFailedError);
        reject(new Error(orgFailedError));
      }

      fetchOrg({
        orgId, slug, include, select
      }).then((organization) => {
        const courseGroupIds = organization?.courseGroupIds || null;

        // Set Locations
        if (organization?.id) {
          dispatch(getLocations({
            orgId: organization.id,
            order,
            locationIds,
            select: locationSelect
          }));
        }

        const data = {
          [reduxKey]: organization,
          courseGroupIds
        };

        dispatch({ type: actionType, ...data });
        resolve(data);
      }).catch((error) => {
        toast.error(error.message);
        reject(error);
      });
    });
  };
};

export const setOrgs = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'organization'
      };

      return client.getEntries(config).then(({ items }) => {
        const organizations = items && items.length !== 0 ? flattenItems(items) : null;

        dispatch({
          type: SET_ORGS,
          organizations
        });
        resolve({ organizations });
      }).catch((error) => {
        toast.error(error.message);
        reject(error);
      });
    });
  };
};

export const updateOrganization = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const {
        organization: { sys: { id: orgId } }
      } = getState();

      const preppedData = formatData(data, 'organization');

      updateEntry({
        id: orgId,
        data: preppedData
      }).then((updatedOrg) => {
        resolve(updatedOrg);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const setOrgEdit = ({ orgId }) => {
  return (dispatch) => {
    const config = {
      content_type: 'organization',
      include: 2,
      'sys.id': orgId
    };

    return new Promise((resolve, reject) => {
      client.getEntries(config).then((response) => {
        const organizationEdit = response.items[0];
        dispatch({
          type: SET_ORG_EDIT,
          organizationEdit
        });

        setTimeout(() => {
          resolve(organizationEdit);
        }, 250);
      }).catch((error) => {
        console.error('setOrgEdit', error);
        reject(error);
      });
    });
  };
};

export const resetOrg = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ORG });
  };
};

export const resetOrgEdit = () => {
  return (dispatch) => {
    dispatch({ type: RESET_ORG_EDIT });
  };
};

export const resetPageOrganization = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PAGE_ORG });
  };
};

export const sendEmail = ({
  subject, message, to, toName
}) => {
  return (_dispatch, getState) => {
    const {
      currentUser: {
        fields: {
          name: userName,
          email: userEmail,
          location: {
            fields: {
              name: orgLocation
            }
          }
        }
      },
      organization: { fields: { name: orgName } }
    } = getState();

    const data = {
      to, // recipient email
      toName, // recipient name
      replyTo: userEmail, // sender's email
      name: userName, // sender's name, current user
      subject,
      message,
      orgName,
      orgLocation
    };

    if (isLocalhost) {
      console.log(data);
    } else {
      // TODO use turbine email api
      emailUser(data);
    }
  };
};
